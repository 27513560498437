import React from 'react';
import { withPrefix } from 'gatsby';
import LazyHero from 'react-lazy-hero';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import HeroContentContainer from '../../components/HeroContentContainer';

const MoreInformation = ({ location }) => (
  <Layout bodyClass="page-how-to-apply" location={location} >
    <SEO title="Insights" />
    <LazyHero className="hero--secondary" imageSrc={withPrefix("/heroes/how-to-apply.jpg")} isCentered={false} minHeight="40vh" opacity={0.2} color="#ffb082">
      <HeroContentContainer>
        <h1>Insights</h1>
      </HeroContentContainer>
    </LazyHero>
    <div className="container pt-2 pt-md-6 pb-2 pb-md-4">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="">
            <h2 className="font-weight-bolder">Insights</h2>
            <p>
              These resources describe student parents and the opportunities they present for institutions and employers in more detail:
            </p>
            <h5 className="font-weight-bold text-orange mt-4 mb-2">From Co-Funders and Partners</h5>
            <div className="additional-resources mb-4">
              <p>
                <div className="media mb-2">
                  <a
                    className="link_img"
                    href="https://www.imaginablefutures.com/learnings/fostering-innovation-support-student-parents/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        trackCustomEvent({
                          category: "Insights",
                          action: "Click",
                          label: `The Rise Prize: Fostering Innovation to Support Student Parents`,
                        });
                      }}
                  >
                    <img src="/logos/rise_prize_cover.svg" className="align-self-center mr-2" alt="Rise Prize Logo" />
                  </a>
                  <div className="media-body">
                    Imaginable Futures and Lumina Foundation:<br/>
                    <a
                      className="text-blue text-underline"
                      /* TODO: This isn't the correct link */
                      href="https://www.imaginablefutures.com/learnings/fostering-innovation-support-student-parents/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        trackCustomEvent({
                          category: "Insights",
                          action: "Click",
                          label: `The Rise Prize: Fostering Innovation to Support Student Parents`,
                        });
                      }}
                    >
                      The Rise Prize: Fostering Innovation to Support Student Parents
                    </a>
                  </div>
                </div>
              </p>
              <p>
                Imaginable Futures and Entangled Solutions:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://info.entangled.solutions/parent-learner-insights-for-innovation"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `Parent Learner Insights for Innovation`,
                    });
                  }}
                >
                  Parent Learner Insights for Innovation
                </a>
              </p>
              <p>
                Lumina Foundation:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://www.luminafoundation.org/todays-student/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `Today’s Student`,
                    });
                  }}
                >
                  Today’s Student
                </a>
              </p>
              <p>
                Ascend at the Aspen Institute:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://drive.google.com/file/d/11N2NpqjxSMKS_36WO1Nkn6HW_Tx4QiYa/view"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `Accelerating Postsecondary Success for Parents`,
                    });
                  }}
                >
                  Accelerating Postsecondary Success for Parents
                </a>
              </p>
              <p>
                The Institute for Women’s Policy Research and Ascend at the Aspen Institute:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://iwpr.org/publications/parents-college-numbers/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `Parents in College By the Numbers`,
                    });
                  }}
                >
                  Parents in College By the Numbers
                </a>
              </p>
              <p>
                The Institute for Women’s Policy Research:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://femchat-iwpr.org/2017/05/12/access-to-child-care-can-improve-student-parent-graduation-rates/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `Access to Child Care Can Improve Student Parent Graduation Rates`,
                    });
                  }}
                >
                  Access to Child Care Can Improve Student Parent Graduation Rates
                </a>
              </p>
            </div>
            <h5 className="font-weight-bold text-orange mt-4 mb-2">From Student Parents</h5>
            <div className="additional-resources mb-4">
              <p>
                Lesley del Rio:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://www.imaginablefutures.com/learnings/guest-blog-one-student-parent-shares-impact-crisis/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `Managing instability and uncertainty`,
                    });
                  }}
                >
                  Managing instability and uncertainty
                </a>
              </p>
              <p>
                Ariel Venturo-Lazo:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://hechingerreport.org/student-voice-a-working-parent-in-college-goes-remote/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `Something has to give`,
                    });
                  }}
                >
                  Something has to give
                </a>
              </p>
            </div>
            <h5 className="font-weight-bold text-orange mb-2">Other Resources:</h5>
            <div className="additional-resources mb-0">
              <p>
                The City University of New York:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://academicworks.cuny.edu/cgi/viewcontent.cgi?article=1101&context=bm_pubs"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `No Time for College? An Investigation of Time Poverty and Parenthood`,
                    });
                  }}
                >
                  No Time for College? An Investigation of Time Poverty and Parenthood
                </a>
              </p>
              <p>
                The Urban Institute:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://www.urban.org/research/publication/supporting-young-parents-they-advance-their-education-and-careers"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `Supporting Young Parents as They Advance Their Education and Careers`,
                    });
                  }}
                >
                  Supporting Young Parents as They Advance Their Education and Careers
                </a>
              </p>
              <p>
                ReUp Education:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://go.reupeducation.com/hubfs/College%20Completion%202019%20Report/College%20Completion%20Report%202019.pdf?__hstc=80978440.0379da05e341cebc15b5a0a9d62271be.1579038418121.1579038418121.1579038418121.1&__hssc=80978440.1.1579038418122"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `Why Students Stopout, Why Stopouts Return`,
                    });
                  }}
                >
                  Why Students Stopout, Why Stopouts Return
                </a>
              </p>
              <p>
                NPR:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://www.npr.org/2019/09/12/759949879/colleges-could-do-more-to-help-student-parents-pay-for-child-care-watchdog-says"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `Colleges Could Do More To Help Student Parents Pay For Child Care, Watchdog Says`,
                    });
                  }}
                >
                  Colleges Could Do More To Help Student Parents Pay For Child Care, Watchdog Says
                </a>
              </p>
              <p>
                Government Accountability Office:<br/>
                <a
                  className="text-blue text-underline"
                  href="https://www.gao.gov/assets/710/701002.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Insights",
                      action: "Click",
                      label: `How About The Rise Prize Could Help Student Parents Access Additional Federal Student Aid`,
                    });
                  }}
                >
                  How About The Rise Prize Could Help Student Parents Access Additional Federal Student Aid
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default MoreInformation;
